@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');

.App {
  text-align: center;
  background-color: black;
}

h1 {
  color: mediumseagreen;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

h2 {
  color: mediumseagreen;
  font-weight: 400;
}

h3 {
  font-family: 'Raleway', sans-serif;
}

#buttonDiv {
  min-width: 100%;
  margin-bottom: 1.2rem;
  /* text-align: center; */
}

.mapContainer h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  color: black;
}

.icon {
  max-width: 10rem;
  padding-right: 3rem;
}

.dropdown {
  padding-bottom: 8px;
}